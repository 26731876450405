import React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import './StatusBar.css'; // External CSS file

const StatusBar = ({ streamedData, fullResponse }) => {
    const location = useLocation();
    const navigate = useNavigate();

    // Define the status bar items with conditions for active states
    const statusItems = [
        {
            id: 1,
            icon: '/icons/icon-search.png',
            description: 'Выберите\nштучку',
            isActive: location.pathname === '/library' && !streamedData,
            onClick: () => navigate('/library')
        },
        { id: 2, icon: '/icons/icon-message-circle.png', description: 'Уточните\nзапрос', isActive: location.pathname !== '/library' && !streamedData },
        { id: 3, icon: '/icons/icon-check-circle.png', description: 'Получите\nрезультат', isActive: location.pathname !== '/library' && streamedData },
    ];

    const activeIndex = statusItems.findIndex(item => item.isActive);

    return (
        <div className='status-wrapper'>
            <div className='padding-top'></div>
        <div 
          className="status-bar" 
          style={{
            '--active-index': activeIndex,
          }}>
            {statusItems.map((item, index) => (
                <div
                    key={item.id}
                    className={`status-item ${item.isActive ? 'active' : 'inactive'} ${index === 0 ? 'clickable' : ''}`}
                    onClick={index === 0 ? item.onClick : null} // Only the first item is clickable
                >
                    <img src={item.icon} className="status-icon" />
                    <div className="status-description">{item.description}</div>
                </div>
            ))}
        </div>
        <div className='fading-line'></div>
        </div>
    );
};

export default StatusBar;

import { useState, useCallback, useEffect, useContext } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { Context } from '../context/GlobalContext';
import { verifyTgData, fetchUserProjects } from '../utils/api';

const UserFetcher = ({ setLoading }) => {
    const { user, setUser, setProjects } = useContext(Context);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const [isFetched, setIsFetched] = useState(false);
    const navigate = useNavigate();
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);
    const re = queryParams.get('re');
    const pathParam = location.pathname.split('/').pop();
    const targetId = queryParams.get('targetId');

    const fetchUserInLocal = useCallback(() => {
        const testUserId = '751b788f-e1a7-405e-a3bb-003d3c8a6bcf';
        console.log('Local mode: Fetching user data.');

        axios.get(`${apiUrl}/api/user`, {
            headers: {
                'user-id': testUserId
            }
        })
        .then(response => {
            setUser(response.data.user);
            setLoading(false);
            setIsFetched(true);

            return fetchUserProjects(testUserId);
        })
        .then(projects => {
            if (projects.length > 0) {
                setProjects(projects);

            } else {
                console.log('No projects');
                navigate('/onboarding');
            }

        })
        .catch(error => {
            console.error('Error fetching user in local environment:', error);
            setLoading(false);
        });
    }, [apiUrl, setUser, setLoading, setProjects]);

    const fetchUserInDevelopment = useCallback(async (initData) => {
        try {
            const response = await verifyTgData(initData);
            if (response.success) {
                setUser(response.userData);

                const projects = await fetchUserProjects(response.userData.id);
                if (projects.length > 0) {
                    setProjects(projects);
                    if (re) {
                        navigate(`/tokens?targetId=${targetId}`);
                    } else {
                        navigate('/library');
                    }
                } else {
                    navigate('/onboarding');
                }

            } else {
                console.error('Error from backend:', response.message);
                navigate('/library');
            }
            setLoading(false);
            setIsFetched(true);
        } catch (error) {
            console.error('Error verifying user in development environment:', error);
            setLoading(false);
        }
    }, [setUser, setLoading, setProjects, navigate]);

    useEffect(() => {
        const environment = process.env.REACT_APP_ENV;

        if (user) {
            setLoading(false);
            return;
        }
        if (!isFetched) {
            if (environment === 'local') {
                fetchUserInLocal();
            } else if (environment === 'development') {

                    const tg = window.Telegram.WebApp;
                    tg.ready();
                    tg.expand();
                    tg.disableVerticalSwipes();
                    fetchUserInDevelopment(tg.initData);
                
            } else if (environment === 'production') {
                
                const tg = window.Telegram.WebApp;
                tg.ready();
                tg.expand();
                tg.disableVerticalSwipes();
                fetchUserInDevelopment(tg.initData);
            }
        }
    }, [user, isFetched, fetchUserInDevelopment, fetchUserInLocal, setLoading]);

    return null;
};

export default UserFetcher;

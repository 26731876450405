import React, { useState, useRef, useContext, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Context } from '../context/GlobalContext';
import './CreateProject.css';
import LoadingState from '../components/LoadingState';

const Onboarding = () => {
    const [projectName, setProjectName] = useState('');
    const [projectDescription, setProjectDescription] = useState('');
    const [filteredProjects, setFilteredProjects] = useState([]);
    const [showFullDescription, setShowFullDescription] = useState(false);
    const [selectedProject, setSelectedProject] = useState(null);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [successMessage, setSuccessMessage] = useState('');
    const { user, projects, setProjects, setSelectedProject: setGlobalSelectedProject } = useContext(Context);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const bottomRef = useRef(null);
    const [isFieldFocused, setIsFieldFocused] = useState(false);
    const navigate = useNavigate();

    const inputRef = useRef(null);
    const textareaRef = useRef(null);

    const maxNameLength = 30;
    const maxDescriptionLength = 2500;

    useEffect(() => {
        if (inputRef.current) {
            inputRef.current.style.width = `${inputRef.current.scrollWidth}px`;
        }
    }, [projectName]);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [projectDescription]);

    const handleFocus = () => {
        setIsFieldFocused(true);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };

    const handleBlur = () => {
        setIsFieldFocused(false);
    };

    // Update the input field and filter the projects as the user types
    const handleChange = (e) => {
        const inputValue = e.target.value;
        setProjectName(inputValue);

        // Filter projects based on the input
        const filtered = projects.filter((project) =>
            project.name.toLowerCase().includes(inputValue.toLowerCase())
        );
        setFilteredProjects(filtered);
        setSelectedProject(null); // Reset selected project if the user changes input
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setError('');
        setSuccessMessage('');
        setLoading(true);

        const newProject = {
            name: projectName,
            description: projectDescription,
            user_id: user.id,
            is_active: true
        };

        try {
            const response = await fetch(`${apiUrl}/api/add-project`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify(newProject)
            });

            const result = await response.json();

            if (response.ok && result.message === 'OK') {
                setSuccessMessage('Проект успешно создан!');

                // Update projects in context
                setProjects(prevProjects => {
                    const updatedProjects = [...prevProjects, result.project];
                    setGlobalSelectedProject(result.project); // Set the newly created project as selected
                    return updatedProjects;
                });

                setTimeout(() => navigate('/library'), 500);
            } else {
                setError('Ошибка при создании проекта. Попробуйте еще раз.');
            }
        } catch (error) {
            console.error('Error creating project:', error);
            setError('Ошибка сети. Попробуйте позже.');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className='wrapper' ref={bottomRef}>
            <div className='padding-top'></div>
            <div className='wrapper1'>
                {loading ? (
                    <LoadingState />
                ) : (
                    <form onSubmit={handleSubmit} className="project-form">
                        <div className="tab-block-header">
                            <h1>Создайте ваш первый проект</h1>
                        </div>
                        <div className="form-group">
                            <label htmlFor="projectName"><span>Название проекта </span><span className="remaining-characters">{maxNameLength - projectName.length}/{maxNameLength}:</span></label>
                            <div className='label-description'>Для вашего удобства</div>
                            <div className='input-wrapper'>
                                <input className='create-project-input'
                                    type="text"
                                    id="projectName"
                                    ref={inputRef}
                                    value={projectName}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    maxLength={maxNameLength}
                                    placeholder="Мой проект"
                                    required
                                />
                            </div>
                        </div>

                        {/* Description field is always visible */}
                        <div className="form-group">
                            <label htmlFor="projectDescription"><span>Описание проекта </span><span className="remaining-characters">{maxDescriptionLength - projectDescription.length}/{maxDescriptionLength}:</span></label>
                            <div className='label-description'>Нейросеть будет использовать описание вашего проекта для генерации контента. Опишите его как можно подробнее</div>
                            <div className='input-wrapper'>
                                <textarea className='create-project-input1'
                                    title="Title"
                                    id="projectDescription"
                                    ref={textareaRef}
                                    value={projectDescription}
                                    onFocus={handleFocus}
                                    onBlur={handleBlur}
                                    onChange={(e) => setProjectDescription(e.target.value)}
                                    maxLength={maxDescriptionLength}
                                    placeholder="Опишите проект, его уникальные особенности, боли и страхи целевой аудитории."
                                    required
                                />
                            </div>
                        </div>

                        {/* Show appropriate action button */}
                        <button type="submit" className="action-button">
                            {selectedProject ? 'Продолжить' : 'Сохранить и продолжить'}
                        </button>

                        {successMessage && <p className="success-message">{successMessage}</p>}
                        {error && <p className="error-message">{error}</p>}
                    </form>
                )}
            </div>
        </div>
    );
};

export default Onboarding;

import React, { useState, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ArrowBackIosNewRoundedIcon from '@mui/icons-material/ArrowBackIosNewRounded';
import { updateProject } from '../utils/api';
import { Context } from '../context/GlobalContext';
import ConfirmationModal from '../components/ConfirmationModal';
import './CreateProject.css';

const EditProject = () => {
    const navigate = useNavigate();
    const location = useLocation();

    const { projectId, projectName: initialProjectName, projectDescription: initialProjectDescription } = location.state || {};

    const { user, setProjects, setSelectedProject } = useContext(Context);

    const [projectName, setProjectName] = useState(initialProjectName || '');
    const [projectDescription, setProjectDescription] = useState(initialProjectDescription || '');
    const [showModal, setShowModal] = useState(false);

    const maxNameLength = 30;
    const maxDescriptionLength = 2500;
    const [isFieldFocused, setIsFieldFocused] = useState(false);
    const bottomRef = useRef(null);

    const handleUpdate = async () => {
        try {
            const response = await updateProject({
                name: projectName,
                description: projectDescription,
                user_id: user.id,
                project_id: projectId,
                is_active: true
            });

            if (response && response.message === 'OK') {

                // Update the projects in the context
                setProjects(prevProjects =>
                    prevProjects.map(project =>
                        project.id === projectId ? { ...project, name: projectName, description: projectDescription } : project
                    )
                );

                // Set the updated project as the selected project
                setSelectedProject({ id: projectId, name: projectName, description: projectDescription });

                navigate('/projects');
            }
        } catch (error) {
            alert('Failed to update project');
        }
    };

    const handleDeleteProject = async () => {
        try {
            const response = await updateProject({
                name: projectName,
                description: projectDescription,
                user_id: user.id,
                project_id: projectId,
                is_active: false
            });

            if (response && response.message === 'OK') {

                setProjects(prevProjects =>
                    prevProjects.filter(project => project.id !== projectId)
                );

                setSelectedProject(null);

                navigate('/projects');
            }
        } catch (error) {
            alert('Failed to delete project');
        }
    };

    const handleDescriptionChange = (e) => {
        setProjectDescription(e.target.value)
    };

    const handleNameChange = (e) => {
        setProjectName(e.target.value)
    };

    const handleFocus = () => {
        setIsFieldFocused(true);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };

    const handleBlur = () => {
        setIsFieldFocused(false);
    };

    return (
        <div className='wrapper' ref={bottomRef}>
            <div className='padding-top'></div>
            <div className='wrapper1'>
                <form
                    onSubmit={(e) => {
                        e.preventDefault();
                        handleUpdate();
                    }}
                >
                    <div className="tab-block-header" style={{marginBottom: '20px'}}>
                        <div
                            style={{ marginRight: '10px', transform: 'translateY(4px)' }}
                            onClick={() => navigate(-1)}
                        >
                            <ArrowBackIosNewRoundedIcon />
                        </div>
                        <h1 style={{margin: 0}}>Настройки проекта</h1>
                    </div>
                    <div className="form-group" style={{marginTop: '0px'}}>
                        <label htmlFor="projectName"><span>Название проекта </span><span className="remaining-characters">{maxNameLength - projectName.length}/{maxNameLength}:</span></label>
                        <div className='label-description'>Для вашего удобства</div>
                        <div className='input-wrapper'>
                            <input className='create-project-input'
                                type="text"
                                id="projectName"
                                value={projectName}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleNameChange}
                                maxLength={maxNameLength}
                                placeholder="Мой проект"
                                required
                            />
                        </div>
                    </div>
                    <div className="form-group">
                        <label htmlFor="projectDescription"><span>Описание проекта </span><span className="remaining-characters">{maxDescriptionLength - projectDescription.length}/{maxDescriptionLength}:</span></label>
                        <div className='label-description'>Нейросеть будет использовать описание вашего проекта для генерации контента. Опишите его как можно подробнее</div>
                        <div className='input-wrapper'>
                            <textarea className='create-project-input1'
                                id="projectDescription"
                                value={projectDescription}
                                onFocus={handleFocus}
                                onBlur={handleBlur}
                                onChange={handleDescriptionChange}
                                maxLength={maxDescriptionLength}
                                placeholder="Описание моего проекта"
                                required
                            />
                        </div>
                    </div>
                    <button type="submit" className="action-button">Сохранить</button>
                    <button
                        type="button"
                        ref={bottomRef}
                        style={{
                            width: '100%',
                            textAlign: 'center',
                            border: 'none',
                            background: 'none',
                            textDecoration: 'underline',
                            color: 'red', marginTop: '20px',
                            fontSize: '16px',
                            fontWeight: '500',
                            fontFamily: '"Jost", sans-serif',
                            marginBottom: isFieldFocused ? '400px' : '0px',
                            cursor: 'pointer'
                        }}
                        onClick={() => setShowModal(true)}
                    >
                        Удалить
                    </button>
                </form>

                {showModal && (
                    <ConfirmationModal
                        title="Точно удалить?"
                        text="Нельзя вернуть то, что удалено"
                        onConfirm={handleDeleteProject}
                        onCancel={() => setShowModal(false)}
                        closeModal={() => setShowModal(false)}
                        cancelLabel="Нет"
                        confirmLabel="Да"
                    />
                )}
            </div>
        </div>
    );
};

export default EditProject;

import React, { useState, useEffect, useContext, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Context } from '../context/GlobalContext';
import LoadingState from '../components/LoadingState';
import StatusBar from '../components/StatusBar';
import { updateUserBalance } from '../utils/api';
import './Assistant.css'

const Assistant = () => {
    const location = useLocation();
    const navigate = useNavigate();
    const { id, name, description, hint, prompt, prompt_length, fieldValue: initialFieldValue = '' } = location.state || {};
    const { user, selectedProject, updateTokensInContext } = useContext(Context);
    const [isLoading, setIsLoading] = useState(false);
    const [streamedData, setStreamedData] = useState('');
    const [error, setError] = useState(null);
    const [fieldValue, setFieldValue] = useState(initialFieldValue || '');
    const [fullResponse, setFullResponse] = useState('');
    const [isButtonLoading, setIsButtonLoading] = useState(false);
    const [pdfSent, setPdfSent] = useState(false);
    const apiUrl = process.env.REACT_APP_API_URL || '';
    const [balance, setBalance] = useState('');
    const accumulatedResponseRef = useRef('');
    const textareaRef = useRef(null);
    const [isFieldFocused, setIsFieldFocused] = useState(false);
    const [isButtonSubmitting, setIsButtonSubmitting] = useState(false);
    const [tokenUsage, setTokenUsage] = useState(null);
    const bottomRef = useRef(null);

    useEffect(() => {
        if (textareaRef.current) {
            textareaRef.current.style.height = 'auto';
            textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
        }
    }, [fieldValue]);

    const updateBalance = async () => {
        const cash = 'out';
        try {
            const data = await updateUserBalance(user.id, id, name, selectedProject.name, tokenUsage, cash);

            const newBalance = user.token_amount - data.tokenRecord.tokens;
            updateTokensInContext(newBalance);

        } catch (error) {
            console.error('Error updating balance');
        }
    };

    useEffect(() => {
        if (fullResponse) {
            updateBalance();
        }

    }, [fullResponse, balance]);

    const handleFocus = () => {
        setIsFieldFocused(true);
        window.scrollTo({ top: document.body.scrollHeight, behavior: 'smooth' });
    };

    const handleBlur = () => {
        setIsFieldFocused(false);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        setIsButtonSubmitting(true);
        setStreamedData('');
        const finalPrompt = `Task: ${prompt}. Substitute <SelectedProject> and <FieldValue> in the <Prompt> with the following information: SelectedProject: ${selectedProject.name}, ${selectedProject.description}. FieldValue: ${fieldValue}. The response must be in the Russian language.`;

        const link = 'https://cookiespooky-gpt4-proxy.hf.space/chunked-stream';

        try {
            const response = await fetch(link, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({ prompt: finalPrompt }),
            });

            if (!response.body) {
                throw new Error('ReadableStream not supported in this environment.');
            }

            const reader = response.body.getReader();
            const decoder = new TextDecoder();
            let done = false;

            while (!done) {
                const { value, done: doneReading } = await reader.read();
                done = doneReading;
                const chunk = decoder.decode(value, { stream: !done });
                
                if (chunk) {
                    // Stream and accumulate the data
                    setStreamedData((prev) => prev + chunk);
                    accumulatedResponseRef.current += chunk;
                }
            }
            
            const responseText = accumulatedResponseRef.current;
            const tokenUsageMatch = responseText.match(/\[Использовано токенов: (\d+)\]/);
            
            if (tokenUsageMatch) {
                setTokenUsage(parseInt(tokenUsageMatch[1], 10));
            }
            
            setFullResponse(accumulatedResponseRef.current);

        } catch (error) {
            console.error('Streaming error:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handleGetPdf = async () => {
        setIsButtonLoading(true);
        const chatId = user.telegram_id;
        console.log(chatId, fullResponse)
        try {
            const response = await fetch(`${apiUrl}/api/getPdf`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    chatId: chatId,
                    content: fullResponse
                })
            });

            if (!response.ok) {
                throw new Error(`HTTP error! status: ${response.status}`);
            }
            setPdfSent(true);
            setIsButtonLoading(false);
        }
        catch (error) {
            console.error('Error while generating pdf:', error);
            setIsButtonLoading(false);
        }
    }

    return (
        <div className="wrapper" >
            <StatusBar streamedData={streamedData} fullResponse={fullResponse} />
            <div className="wrapper1" style={{ paddingTop: '0' }}>
                {isLoading ? (
                    <LoadingState />
                ) : (
                    <div>
                        <h1>{name}</h1>
                        <h2>{description} для проекта "{selectedProject?.name || '...'}"</h2>
                        <div>
                            <div className='bubble-wrapper'>
                                <img className='thingy-img' src={`${process.env.PUBLIC_URL}/assets/ai-thingy.png`} alt="thingy" />
                                <div className='bubble-text'>
                                    {streamedData ? (
                                        <div>

                                            <div className='streamed-response'>
                                                <div>{streamedData}</div>
                                            </div>
                                            {error && (
                                                <div>
                                                    <div className="error-message">{error}</div>
                                                    <button className='action-button' onClick={handleSubmit}>
                                                        {isButtonLoading ? 'Загрузка...' : 'Попробовать снова'}
                                                    </button>
                                                </div>
                                            )}
                                            <div>
                                                {fullResponse && !pdfSent && (
                                                    <button style={{ border: 'none', background: 'none', fontWeight: 'bold', fontSize: '16px', textDecoration: 'underline', color: 'white', paddingLeft: '0', paddingBottom: '4px', fontFamily: '"Jost", sans-serif', marginTop: '10px', cursor: 'pointer' }} onClick={handleGetPdf}>
                                                        {isButtonLoading ? 'Загрузка...' : 'Скачать PDF'}
                                                    </button>
                                                )}
                                                {pdfSent && <div className="pdf-sent-message" style={{ textAlign: 'left', marginTop: '10px', fontSize: '12px' }}>PDF-документ отправлен в <a style={{ textDecoration: 'underline', fontWeight: 'bold', color: 'white', marginTop: '10px', cursor: 'pointer' }} href={process.env.REACT_APP_BOT_URL}>бот</a> </div>}
                                            </div>
                                        </div>
                                    ) : (
                                        <div>
                                            {hint}
                                        </div>)}
                                    {error ? (<div>{error}</div>) : (null)}
                                </div>
                            </div>
                        </div>
                        {!streamedData && (
                            <form onSubmit={handleSubmit} className="project-form">
                                <div className="form-group">
                                    <div className='input-wrapper'>
                                        <textarea
                                            type="text"
                                            id="additionalField1"
                                            value={fieldValue}
                                            ref={textareaRef}
                                            onFocus={handleFocus}
                                            onBlur={handleBlur}
                                            onChange={(e) => setFieldValue(e.target.value)}
                                            placeholder="Ваш ответ"
                                            required
                                        />
                                    </div>
                                </div>
                                <div style={{ opacity: '0.7', marginTop: '10px' }}>Стоимость генерации: ~{prompt_length} <span><img src="/icons/coin.png" style={{ width: '16px', transform: 'translateY(1px)' }} alt="coin" /></span></div>
                                <button
                                    type="submit"
                                    className="gradient-button"
                                    ref={bottomRef}
                                    style={{
                                        marginBottom: isFieldFocused ? '400px' : '0px',
                                        background: user.token_amount < prompt_length ? 'black' : '',
                                    }}
                                    disabled={isButtonSubmitting}
                                    onClick={() => {
                                        if (user.token_amount < prompt_length) {
                                            navigate('/tariffs');
                                        }
                                    }}
                                >
                                    {user.token_amount < prompt_length
                                        ? 'Пополнить баланс'
                                        : isButtonSubmitting
                                            ? 'Ждем ответ от штучки...'
                                            : 'Сгенерировать'
                                    }
                                    {user.token_amount < prompt_length ? '' : <span className="gradient-button-icon"><img src='/icons/magic.png' style={{ height: '16px' }} alt='magic' /></span>}
                                </button>
                            </form>
                        )}
                    </div>
                )}
            </div>
        </div>
    )
};

export default Assistant;